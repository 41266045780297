import React from 'react'
import { Link } from 'react-scroll';
import '../style/value.css'
import '../images/aroplanicons2.svg'
import cehck from '../images/check1.png'

const Value = () => {
    return (
        <>
            <div className="servicebenefites11-section-area section-padding5">
                <div className="container">
                    <div className="row align-items-center" >
                        <div id='giveTopMargin' className="col-lg-5 aos-init" data-aos="fade-left" data-aos-duration="800" data-aos-easing="linear" style={{ textAlign: 'start', marginTop: '25px' }}>
                            <span className="font-outfit font-15 lineh-16 weight-500 color-h11 aropaln2 d-inline-block margin-b24">Business Model</span>
                            <h1 className="font-outfit font-40 lineh-52 weight-600 color text-capitalize margin-b20 aos-init" data-aos="fade-right" data-aos-duration="1000" data-aos-easing="linear" style={{ textShadow: '4px 4px 5px rgba(0, 0, 0, 0.3)', color: 'rgba(32, 64, 99, 0.8)', marginTop: "10px", fontFamily: 'inherit' }}>Value That Matters</h1>
                            <p className="font-outfit font-15 lineh-26 weight-400 color-21 margin-b">Our business model revolves around our clients, and we plan around their needs, our primary aim is to make it quick while maintaining quality.</p>
                            <p className="font-outfit font-15 lineh-26 weight-400 color-21">Our internal quality assurance committee have audits at regular intervals to ensure quality at every stage.</p>
                            <Link to="services" smooth={true} duration={800} className="font-outfit font-15 lineh-16 weight-700 color themebtn11 d-inline-block margin-t32" id='btnKnow'><span>Explore us...</span></Link>
                        </div>
                        <div className="col-lg-7">
                            <div className="immigritation-listarea">
                                <div className="row">
                                    <div className="col-lg-6 aos-init" id='setValue' data-aos="zoom-in" data-aos-duration="800">
                                        <div className="row immigrationarea">
                                            <div className='col-2'>
                                                <span className="ts"><div className="author0mages"> <img src={cehck} alt="" className="ts" /></div></span>
                                            </div>
                                            <div className='col-10'>
                                                <p className="font-15 font-outfit lineh-16 weight-600 color ts" id='changeColor'><span className='giveMarg'>Pay for what you choose</span></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 aos-init" id='setValue' data-aos="zoom-in" data-aos-duration="1000">
                                        <div className="row immigrationarea">
                                            <div className='col-2'>
                                                <span className="ts"><div className="author0mages"> <img src={cehck} alt="" className="ts" /></div></span>
                                            </div>
                                            <div className='col-10'>
                                                <p className="font-15 font-outfit lineh-16 weight-600 color ts" id='changeColor'><span className='giveMarg'>Tailored services
                                                </span></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 aos-init" id='setValue' data-aos="zoom-out" data-aos-duration="1200">
                                        <div className="row immigrationarea">
                                            <div className='col-2'>
                                                <span className="ts"><div className="author0mages"> <img src={cehck} alt="" className="ts" /></div></span>
                                            </div>
                                            <div className='col-10'>
                                                <p className="font-15 font-outfit lineh-16 weight-600 color ts" id='changeColor'><span className='giveMarg'>Strong analytical skills
                                                </span></p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-lg-6 aos-init" id='setValue' data-aos="zoom-out" data-aos-duration="1400">
                                        <div className="row immigrationarea">
                                            <div className='col-2'>
                                                <span className="ts"><div className="author0mages"> <img src={cehck} alt="" className="ts" /></div></span>
                                            </div>
                                            <div className='col-10'>
                                                <p className="font-15 font-outfit lineh-16 weight-600 color ts" id='changeColor'><span className='giveMarg'>Helping in peak seasons
                                                </span></p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-lg-6 aos-init" id='setValue' data-aos="zoom-out" data-aos-duration="1000">
                                        <div className="row immigrationarea">
                                            <div className='col-2'>
                                                <span className="ts"><div className="author0mages"> <img src={cehck} alt="" className="ts" /></div></span>
                                            </div>
                                            <div className='col-10'>
                                                <p className="font-15 font-outfit lineh-16 weight-600 color ts" id='changeColor'><span className='giveMarg'>Detail Oriented (No chance of errors)
                                                </span></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 aos-init" id='setValue' data-aos="zoom-out" data-aos-duration="1400">
                                        <div className="row immigrationarea">
                                            <div className='col-2'>
                                                <span className="ts"><div className="author0mages"> <img src={cehck} alt="" className="ts" /></div></span>
                                            </div>
                                            <div className='col-10'>
                                                <p className="font-15 font-outfit lineh-16 weight-600 color ts" id='changeColor'><span className='giveMarg'>Personalized support whole year</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 aos-init" id='setValue' data-aos="zoom-out" data-aos-duration="1400">
                                        <div className="row immigrationarea">
                                            <div className='col-2'>
                                                <span className="ts"><div className="author0mages"> <img src={cehck} alt="" className="ts" /></div></span>
                                            </div>
                                            <div className='col-10'>
                                                <p className="font-15 font-outfit lineh-16 weight-600 color ts" id='changeColor'><span className='giveMarg'>Compliance management Client feedback loop
                                                </span></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 aos-init" id='setValue' data-aos="zoom-in" data-aos-duration="1400">
                                        <div className="row immigrationarea">
                                            <div className='col-2'>
                                                <span className="ts"><div className="author0mages"> <img src={cehck} alt="" className="ts" /></div></span>
                                            </div>
                                            <div className='col-10'>
                                                <p className="font-15 font-outfit lineh-16 weight-600 color ts" id='changeColor'><span className='giveMarg'>Get a dedicated manager for your operations
                                                </span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="assets/images/elementor/elementor77.png" alt="" className="elementors77" />
            </div>
        </>
    )
}

export default Value
